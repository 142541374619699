<template>
  <v-container
    id="contact-profile"
    fluid
    tag="section"
  >
    <v-overlay :value="isOverlayVisible">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
        md="4"
        offset-md="8"
        offset-lg="8"
      >
        <v-select
          v-model="selectedContact"
          :items="contacts"
          item-text="displayName"
          item-value="ContactID"
          label="Select a contact"
          solo
          class="dropdown"
          @change="updateContactSelection"
        />
      </v-col>
    </v-row>
    <!--  Show if message/group not selected  -->
    <v-row v-if="!isMessageDataAvailable">
      <v-col
        cols="12"
      >
        <div class="selectMessageInfo">
          Select a contact to get message histories
        </div>
      </v-col>
    </v-row>

    <v-row v-if="isMessageDataAvailable">
      <v-col
        cols="12"
      >
        <div class="messageInfoWrapper">
          <div class="messageMetaData">
            <h3>Contact Name: {{ selectedContact.FirstName + " " + selectedContact.LastName }}</h3>
            <h3>CellNumber: {{ selectedContact.CellNumber }}</h3>
            <p class="statsData">
              Number of deliveries: {{ stats.delivered }}
            </p>
            <p>Failed deliveries: {{ stats.failed }}</p>
            <p>Undelivered messages: {{ stats.undelivered }}</p>
          </div>
          <div class="graphWrapper">
            <!-- <div id="chart" /> -->
            <div
              v-if="isPieChartVisible"
              class="pieChartWrapper"
            >
              <pie-chart :items="pieChartData" />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="isMessageDataAvailable">
      <v-col
        cols="12"
        md="3"
        offset-md="3"
        offset-lg="3"
        class="statusFilterWrapper"
      >
        <v-select
          v-model="selectedGroupId"
          :items="groups"
          item-text="name"
          item-value="id"
          label="Select a group"
          solo
          class="dropdown"
          @change="updateGroupSelection"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="statusFilterWrapper"
      >
        <v-select
          v-model="selectedStatus"
          :items="status"
          item-text="name"
          item-value="id"
          label="Filter by status"
          solo
          class="dropdown"
          @change="filterByStatus"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="statusFilterWrapper"
      >
        <v-select
          v-model="selectedMessageType"
          :items="messageTypes"
          item-text="name"
          item-value="id"
          label="Filter by message type"
          solo
          class="dropdown"
          @change="updateMessageTypes"
        />
      </v-col>
      <v-col
        cols="12"
      >
        <v-data-table
          :headers="headers"
          :items="allMessages"
          item-key="id"
          sort-by="Status"
        >
          <template
            v-slot:item.Message="{ item }"
          >
            {{ item.Message }}
          </template>
          <template v-slot:item.language="{ item }">
            <div class="senderActions">
              <div
                class="mr-1"
              >
                <v-btn
                  color="primary"
                  outlined
                  x-small
                  @click="translateTxt('en', item)"
                >
                  en
                </v-btn>
              </div>
              <div
                class="mr-1"
              >
                <v-btn
                  color="primary"
                  outlined
                  x-small
                  @click="translateTxt('zh', item)"
                >
                  zh
                </v-btn>
              </div>
              <div
                class="mr-1"
              >
                <v-btn
                  color="primary"
                  outlined
                  x-small
                  @click="translateTxt('es', item)"
                >
                  es
                </v-btn>
              </div>
            </div>
          </template>
          <template v-slot:no-data>
            <p class="mb-4 mt-4">
              No messages to display
            </p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div class="py-3" />
  </v-container>
</template>

<script>
  import HttpConnector from '../../../utils/http-connector'
  import moment from 'moment'
  import PieChart from '../../../components/PieChart'
  import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
  // import css
  import 'frappe-charts/dist/frappe-charts.min.css'
  const { gapi } = window

  export default {
    components: {
      PieChart,
    },
    props: {
      contactId: {
        required: false,
        type: Number,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      googleTranslateApiKey: process.env.VUE_APP_GOOGLE_TRANSLATE_API,
      googleTranslateClientId: process.env.VUE_APP_GOOGLE_TRANSLATE_API_CLIENT_ID,
      dialog: false,
      selectedLanguage: 'en',
      headers: [
        { text: 'Cell Number', value: 'CellNumber', width: '10%' },
        { text: 'Date Sent', value: 'dateSent', width: '10%' },
        { text: 'Message', value: 'Message', width: '40%' },
        { text: 'Language', value: 'language', width: '15%' },
        { text: 'Group', value: 'GroupName', width: '15%' },
        { text: 'Sender', value: 'CellFrom', width: '5%' },
        { text: 'Status', value: 'status', width: '5%' },
        // { text: 'Status Message', value: 'ErrorMessage' },
      ],
      snackbar: false,
      notification: 'Unknown',
      isOverlayVisible: false,
      selectedGroup: {
        id: -1,
        name: 'All Groups',
      },
      selectedGroupId: -1,
      selectedMessageType: '1',
      selectedMessage: null,
      selectedContact: null,
      selectedStatus: 'delivered',
      contacts: [],
      groups: [],
      messages: [],
      isMessageDataAvailable: false,
      sentMessages: [],
      failedMessages: [],
      deliveredMessages: [],
      undeliveredMessages: [],
      allMessages: [],
      status: [{
        name: 'Delivered',
        id: 'delivered',
      }, {
        name: 'Failed',
        id: 'failed',
      }, {
        name: 'Undelivered',
        id: 'undelivered',
      }],
      messageTypes: [{
        name: 'Message Sent',
        id: '1',
      }, {
        name: 'Responses',
        id: '2',
      }],
      pieChartData: [],
      isPieChartVisible: false,
      stats: null,
    }),

    async created () {
      this.usremail = localStorage.getItem('username')
      await this.initialize()

      if (this.$route && this.$route.params && this.$route.params.id) {
        await this.fetchContacts(parseInt(this.$route.params.id))
      } else {
        await this.fetchContacts()
      }
    },

    mounted () {},

    methods: {
      async initialize () {
        gapi.load('client:auth2', this.initClient)
      },

      async fetchGroupByContactId (id) {
        try {
          this.isOverlayVisible = true
          // First fetch all available groups
          const { data = [] } = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}contact/${id}/groups?code=${this.apiCode}`)

          this.groups = []
          this.groups.push({
            id: -1,
            name: 'All Groups',
          })
          data.forEach((group) => {
            this.groups.push({
              id: group.GroupID,
              name: group.Name,
            })
          })
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      async translateTxt (lang, item) {
        this.selectedLanguage = lang

        const { body } = await gapi.client.language.translations.list({
          q: item.Message,
          target: lang,
        })

        const response = JSON.parse(body)
        item.Message = response.data.translations[0].translatedText

        this.allMessages.splice(item.id, 1, item)
      },

      initClient () {
        const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/translate/v2/rest']

        const SCOPES = ['https://www.googleapis.com/auth/cloud-platform', 'https://www.googleapis.com/auth/cloud-translation', 'https://www.googleapis.com/auth/plus.business.manage', 'https://www.googleapis.com/auth/business.manage']
        const { gapi } = window
        gapi.client.init({
          apiKey: this.googleTranslateApiKey,
          clientId: this.googleTranslateClientId,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        }).then(() => {}, (e) => {})
      },

      async fetchContacts (cid = 0) {
        this.isOverlayVisible = true
        if (this.selectedGroup.id === -1) {
          const contactsResponse = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}ListContacts?code=${this.apiCode}`, {
            params: {
              UserID: this.usremail,
            },
          })
          this.contacts = contactsResponse.data.map(contact => {
            return {
              ...contact,
              displayName: `${contact.FirstName} ${contact.LastName} (${contact.CellNumber})`,
            }
          })

          if (this.contacts.length > 0 && cid === 0) {
            this.updateContactSelection(this.contacts[0].ContactID)
          } else if (this.contacts.length > 0 && cid !== 0) {
            this.updateContactSelection(cid)
          } else {
            this.isOverlayVisible = false
          }
        }
      },

      async fetchStats (groupId = this.selectedContact.GroupID) {
        // Fetch stats data for selected contact
        const stats = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}contacthistorycnt/${this.selectedContact.CellNumber}/${groupId}?code=${this.apiCode}`)
        const sent = stats.data.find(stat => stat.StatusMessage === 'sent')
        const delivered = stats.data.find(stat => stat.StatusMessage === 'delivered')
        const failed = stats.data.find(stat => stat.StatusMessage === 'failed')
        const undelivered = stats.data.find(stat => stat.StatusMessage === 'undelivered')
        this.stats = {
          sent: sent && sent.Count ? sent.Count : 0,
          delivered: delivered && delivered.Count ? delivered.Count : 0,
          failed: failed && failed.Count ? failed.Count : 0,
          undelivered: undelivered && undelivered.Count ? undelivered.Count : 0,
        }
      },

      async updateGroupSelection (selectedGroupId) {
        this.selectedGroupId = selectedGroupId
        this.selectedGroup = this.groups.find(g => g.id === selectedGroupId)

        if (this.selectedGroupId === -1) {
          await this.fetchStats(this.selectedContact.GroupID)
          this.loadPieChart()

          this.allMessages = this.formatMessages([
            ...this.failedMessages,
            ...this.deliveredMessages,
          ])
        } else {
          await this.fetchStats(selectedGroupId)
          this.loadPieChart()

          const messagesArr = [
            ...this.failedMessages,
            ...this.deliveredMessages,
          ]
          const groupedMsg = messagesArr.filter((item) => {
            return item.GroupName.toLowerCase() === this.selectedGroup.name.toLowerCase()
          })

          this.allMessages = this.formatMessages([
            ...groupedMsg,
          ])
        };
      },

      async updateContactSelection (selectedContactId) {
        this.isMessageDataAvailable = false
        this.selectedContact = this.contacts.find(contact => contact.ContactID === selectedContactId)
        // Fetch all messages of selected contact
        this.isOverlayVisible = true
        await Promise.all([
          this.fetchMessagesOfSelectedContact(),
          this.fetchStats(),
          this.fetchGroupByContactId(selectedContactId),
        ])
        this.isMessageDataAvailable = true
        // this.loadLineGraph()
        this.loadPieChart()
        this.selectedGroup = {
          id: -1,
          name: 'All Groups',
        }
        this.selectedGroupId = -1
        this.isOverlayVisible = false
      },

      async updateMessageTypes (newMessageType) {
        // Reset status selection
        this.selectedStatus = 'delivered'
        this.selectedMessageType = newMessageType
        this.selectedGroup = {
          id: -1,
          name: 'All Groups',
        }
        this.selectedGroupId = -1
        await this.fetchMessagesOfSelectedContact()
      },

      async fetchMessagesOfSelectedContact () {
        this.isOverlayVisible = true
        try {
          const params = {
            CellNumber: this.selectedContact.CellNumber,
            MessageType: this.selectedMessageType,
          }
          // if (this.selectedContact.GroupID) {
          //   params.GroupID = this.selectedContact.GroupID
          // }
          const insightResponse = await Promise.all([
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'delivered',
            }),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'failed',
            }),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'undelivered',
            }),
          ])
          this.deliveredMessages = insightResponse[0].data.map(m => {
            return { ...m, status: 'delivered' }
          })
          this.failedMessages = insightResponse[1].data.map(m => {
            return { ...m, status: 'failed' }
          })
          this.undeliveredMessages = insightResponse[2].data.map(m => {
            return { ...m, status: 'undelivered' }
          })
          this.allMessages = this.formatMessages([
            ...this.deliveredMessages,
          ])
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      loadPieChart () {
        this.isPieChartVisible = false
        setTimeout(() => {
          // Prepare pe chart data
          this.pieChartData = [{
            label: 'Delivered',
            backgroundColor: '#00f07c',
            value: this.stats.delivered,
          }, {
            label: 'Failed',
            backgroundColor: '#eb4034',
            value: this.stats.failed,
          }, {
            label: 'Undelivered',
            backgroundColor: '#f56942',
            value: this.stats.undelivered,
          }]
          this.isPieChartVisible = true
        }, 200)
      },

      loadLineGraph () {
        setTimeout(() => {
          this.chart = new Chart('#chart', {
            title: 'Campaign Statistics ',
            data: this.chartData,
            type: 'axis-mixed', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
            height: 250,
            colors: ['#427ef5', '#00f07c', '#f00044'],
          })
        }, 1000)
      },

      formatMessages (messages) {
        return messages.map((message, index) => {
          return {
            ...message,
            id: index,
            dateSent: moment(message.CreateDate).format('MM-DD-YYYY'),
          }
        })
      },

      formatMessagesByGroup (messages) {
        if (this.selectedGroup.id === -1) return messages
        const groupedMsg = messages.filter((item) => {
          return item.GroupName.toLowerCase() === this.selectedGroup.name.toLowerCase()
        })
        return groupedMsg
      },

      filterByStatus (status) {
        this.selectedStatus = status
        switch (status.toLowerCase()) {
          case 'delivered': {
            this.allMessages = this.formatMessages([
              ...this.deliveredMessages,
            ])
            this.allMessages = this.formatMessagesByGroup(this.allMessages)
            break
          }
          case 'failed': {
            this.allMessages = this.formatMessages([
              ...this.failedMessages,
            ])
            this.allMessages = this.formatMessagesByGroup(this.allMessages)
            break
          }
          case 'undelivered': {
            this.allMessages = this.formatMessages([
              ...this.undeliveredMessages,
            ])
            this.allMessages = this.formatMessagesByGroup(this.allMessages)
            break
          }
          default: {
            this.allMessages = this.formatMessages([
              ...this.failedMessages,
              ...this.deliveredMessages,
            ])
          }
        }
      },
    },
  }

</script>

<style scoped lang="scss">
  .messageInfoWrapper {
    width: 100%;
    background-color: white;
    min-height: 100px;
    max-height: 280px;
    display: flex;
    @media only screen and (max-width: 968px) {
        display: block;
    }
    .messageMetaData {
      width: 50%;
      padding: 40px 20px 30px 60px;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      h3 {
        margin-bottom: 6px;
      }
      .dateDisplay {
        margin-bottom: 75px;
      }
      p {
        margin-bottom: 3px;
      }
    }
    .graphWrapper {
      width: 50%;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .pieChartWrapper {
        width: 280px;
      }
    }
  }
  .mr-1 {
    margin-right: 5px;
  }
  .senderActions {
    display: flex;
  }
  .selectMessageInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin-bottom: 200px;
  }
    .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
  .statusFilterWrapper {
    margin-top: 25px;
    margin-bottom: -30px;
  }
  .orLabel {
    text-align: center;
    // align-items: center;
    padding-top: 25px;
  }
  .reset {
    color: red;
    cursor: pointer;
    text-align: center;
  }
  .statsData {
    margin-top: 5rem;
  }
</style>
